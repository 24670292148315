<template>
  <div class="login-content-box">
    <div class="login-content-icon-img">
      <img src="../../../assets/img/code/loginLog.png" alt />
    </div>
    <div class="login-right">
      <div class="row">
        <div class="Login-header">
          <div class="Login-container">
            <div class="lc-titel">
              <h2>{{ getString(strings.User_Lolgin_Register_An_Account) }}</h2>
              <div class="lc-titel-a" style="width: 71%">
                <span class="lc-zi"
                  >{{
                    getString(strings.User_Lolgin_Already_Have_An_Account)
                  }}，
                  <a href="javascript:;" @click="ClickLoginShow" class="sign-a">
                    {{ getString(strings.User_Lolgin_To_Log_In) }}
                    <a-icon type="double-right" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="lright-cont">
          <div class="login-content">
            <div class="login">
              <a-form :form="form" @submit="handleSubmitSignIn">
                <div class="drop-down d-quhao">
                  <img
                    src="../../../assets/img/code/phone_number_logo.png"
                    alt=""
                    style="margin-bottom: 2px"
                  />
                  <country-code-selector
                    :countryCode.sync="countryCode"
                    class="qudeng"
                  ></country-code-selector>
                </div>
                <a-form-item>
                  <a-input
                    class="login-phone-number"
                    oninput="if(value.length>11)value=value.slice(0,11);value=value.replace(/[^\d]/g,'')"
                    ref="phoneNumber"
                    @blur="phoneNumberBlurForValidate"
                    :placeholder="
                      getString(strings.User_Lolgin_Please_Enter_Phone_Number)
                    "
                    allow-clear
                    v-decorator="[
                      'phoneNumber',
                      {
                        rules: [
                          {
                            validator: phoneNumber,
                          },
                          {
                            trigger: 'change',
                          },
                        ],
                      },
                    ]"
                  >
                  </a-input>
                </a-form-item>
                <div class="drop-down">
                  <img src="../../../assets/img/code/captcha1220.png" alt="" />
                </div>
                <a-form-item class="drcode">
                  <div class="dr-agroup">
                    <a-input
                      oninput="if(value.length>6)value=value.slice(0,6);value=value.replace(/[^\d]/g,'')"
                      :placeholder="
                        getString(
                          strings.User_Lolgin_Please_Enter_Verification_Code
                        )
                      "
                      @blur="captchaBlurForValidate"
                      ref="CodeValue"
                      allow-clear
                      v-decorator="[
                        'captcha',
                        {
                          rules: [
                            {
                              message: getString(
                                strings.Input_Please_Enter_Verification_Code
                              ),
                            },
                            {
                              validator: validataToCode,
                            },
                          ],
                        },
                      ]"
                    >
                    </a-input>
                    <div @click="handleSubmitCode">
                      <a-button
                        class="dra-btn scode"
                        disabled
                        v-if="showTimeData"
                      >
                        {{ timeData }}
                        <span>s</span>
                      </a-button>
                      <a-button class="dra-btn" v-else>{{
                        getString(strings.User_Lolgin_Obtain)
                      }}</a-button>
                    </div>
                  </div>
                </a-form-item>
                <div class="drop-down">
                  <img
                    src="../../../assets/img/code/pwd_input_logo.png"
                    alt=""
                  />
                </div>
                <a-form-item>
                  <a-input-password
                    oninput="if(value.length>24)value=value.slice(0,24)"
                    :placeholder="
                      getString(strings.User_Lolgin_Please_Enter_Password)
                    "
                    ref="password"
                    @blur="passwordBlurForValidate"
                    allow-clear
                    v-decorator="[
                      'password',
                      {
                        rules: [
                          {
                            message: getString(
                              strings.Input_Please_Enter_The_Password
                            ),
                          },
                          {
                            validator: validateToNextPassword,
                          },
                        ],
                      },
                    ]"
                    type="password"
                  >
                  </a-input-password>
                </a-form-item>
                <div class="drop-down">
                  <img
                    src="../../../assets/img/code/pwd_input_logo.png"
                    alt=""
                  />
                </div>
                <a-form-item>
                  <a-input-password
                    oninput="if(value.length>24)value=value.slice(0,24)"
                    :placeholder="
                      getString(strings.User_Lolgin_Confirm_Password)
                    "
                    ref="confirm"
                    @blur="confirmBlurForValidate"
                    allow-clear
                    v-decorator="[
                      'confirm',
                      {
                        rules: [
                          {
                            message: getString(
                              strings.Input_Confirm_The_New_Password
                            ),
                          },
                          {
                            validator: compareToFirstPassword,
                          },
                        ],
                      },
                    ]"
                    type="password"
                  >
                  </a-input-password>
                </a-form-item>
                <div class="drop-down">
                  <img
                    src="../../../assets/img/code/Invitation_code_logo.png"
                    alt=""
                  />
                </div>
                <div class="drop-down-before">{{getString(strings.User_Lolgin_Optional)}}</div>
                <a-form-item>
                  <div class="dr-agroup">
                    <a-input
                      allow-clear
                      :placeholder="
                        getString(
                          strings.User_Lolgin_Please_Enter_Invitation_Code
                        )
                      "
                      ref="invitation"
                      v-decorator="[
                        'invitation',
                        {
                          rules: [
                            {
                              required: true,
                              message: getString(
                                strings.User_Lolgin_Please_Correct_Enter_Invitation_Code
                              ),
                            },
                            {
                              validator: invitationToCode,
                            },
                          ],
                        },
                        (maxLength = 12),
                      ]"
                    >
                    </a-input>
                  </div>
                </a-form-item>
                <a-form-item v-bind="tailFormItemLayout">
                  <a-button
                    class="Login-Button"
                    type="primary"
                    html-type="submit"
                  >
                    {{ getString(strings.User_Lolgin_Registered) }}
                  </a-button>
                </a-form-item>
              </a-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import LoginRequest from "../../../network/login";
import countryCodeSelector from "vue-country-code-selector";
import { mapMutations } from "vuex";
import {
  postUserRegisterCaptcha,
  postUserRegister,
  postUserMe,
  postUserPasswordLogin,
} from "../../../common/netWork/base_api";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
import { doLogin, setJwt } from "../../../common/netWork/base";
import sign from "../../../assets/css/login/sign.less";
import regExp from "../regular.js";
const { phoneNumberRegExp } = regExp;

export default {
  name: "Login",
  data() {
    return {
      confirmDirty: false,
      // autoCompleteResult: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      },
      SignInData: {
        areaCode: "",
        phoneNumber: "",
        invitation:""
      },
      timeData: "",
      showTimeData: false,
      countryCode: 86,
      phoneNumberValidateCallback: null,
      captchaValidateCallback: null,
      passwordValidateCallback: null,
      confirmValidateCallback: null,
      strings: strings,
    };
  },
  components: {
    //国家区号
    countryCodeSelector,
  },
  beforeCreate() {
    //验证
    this.form = this.$form.createForm(this, { name: "register" });
  },
  methods: {
    ...mapMutations(["changeLogin", "setUserIfLogin", "showLoginModel"]),
    getString(i) {
      return getString(i);
    },
    // 获取验证码
    handleSubmitCode(e) {
      this.SignInData.areaCode = "+" + this.countryCode;
      this.SignInData.phoneNumber = this.$refs.phoneNumber.value;
      const SignInData = this.SignInData;
      this.form.validateFieldsAndScroll(
        ["phoneNumber"],
        { first: true },
        (err, values) => {
          if (!err) {
            postUserRegisterCaptcha(
              SignInData,
              (res) => {
                if (res == false) {
                  this.showTimeData = res;
                } else {
                  this.showTimeData = true;
                  this.timeData = 60;
                  let countDown = setInterval(() => {
                    if (this.timeData < 1) {
                      this.timeData = 0;
                      this.showTimeData = false;
                      clearInterval(countDown);
                    } else {
                      this.timeData--;
                    }
                  }, 1000);
                }
                // console.log("获取验证码成功");
              },
              (error) => {
                this.$message.error(error.desc);
              }
            );
          }
        }
      );
    },

    // 注册账号
    handleSubmitSignIn(e) {
      e.preventDefault();
      this.SignInData.areaCode = "+" + this.countryCode;
      this.SignInData.phoneNumber = this.$refs.phoneNumber.value;
      this.SignInData.paddword = this.$refs.password.value;
      this.SignInData.invitation = this.$refs.invitation.value;
      const SignInData = this.SignInData;
      // e.preventDefault();
      var _this = this;
      this.form.validateFieldsAndScroll(
        ["phoneNumber", "password", "captcha"],
        { first: true },
        (err, values) => {
          // console.log(values);
          if (!err) {
            values.areaCode = this.SignInData.areaCode;
            postUserRegister(
              values,
              (res) => {
                doLogin(res);
                this.$message.success(
                  getString(strings.Message_Tips_Login_Success)
                );
                this.showLoginModel(false);
                this.setUserIfLogin(true);
                this.$emit("ShowHome", false);
              },
              (error) => {
                this.$message.error(error.desc);
              }
            );
          }
        }
      );
    },
    // 失去焦点
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    // 登录注册切换
    ClickLoginShow(ShowLogin) {
      this.$emit("ClickLoginShow", "Login");
    },
    // 确认密码验证
    compareToFirstPassword(rule, value, callback) {
      this.confirmValidateCallback = callback;
      // const form = this.form;
      if (this.confirm != "") {
        if (!(value != this.$refs.password.value)) {
          callback();
        }
      } else {
        callback();
      }
    },
    confirmBlurForValidate(e) {
      if (this.confirmValidateCallback == null) {
        return;
      }
      let value = this.$refs.confirm.value;
      if (value != this.$refs.password.value) {
        this.confirmValidateCallback(
          getString(strings.Input_The_Passwords_Are_Inconsistent)
        );
      } else {
        this.confirmValidateCallback();
      }
      return;
    },

    // 密码验证
    validateToNextPassword(rule, value, callback) {
      this.passwordValidateCallback = callback;
      // const form = this.form;
      if (this.paddword != "") {
        const PassWordValue = /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-z\d#@!~%^&*]{8,24}/i;
        if (!(!PassWordValue.test(value) && value)) {
          callback();
        }
      } else {
        callback();
      }
    },
    passwordBlurForValidate(e) {
      if (this.passwordValidateCallback == null) {
        return;
      }
      let value = this.$refs.password.value;
      if (this.paddword != "") {
        const PassWordValue = /((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-z\d#@!~%^&*]{8,24}/i;
        if (!PassWordValue.test(value) && value) {
          this.passwordValidateCallback(
            getString(strings.Input_The_Password_Format_Is_Incorrect)
          );
        } else {
          this.passwordValidateCallback();
        }
      } else {
        this.passwordValidateCallback(
          getString(strings.Input_Please_Enter_The_Password)
        );
      }
    },

    // 手机号验证
    phoneNumber(rule, value, callback) {
      this.phoneNumberValidateCallback = callback;
      if ("" + this.countryCode == "86") {
        var iphoneNumber = phoneNumberRegExp;
        if (!(!iphoneNumber.test(value) && value)) {
          callback();
        }
      } else {
        if ((value + "").length > 4 && (value + "").length < 20) {
          callback();
        }
      }
    },
    //手机号验证焦点离开判断
    phoneNumberBlurForValidate(e) {
      let value = this.$refs.phoneNumber.value;
      if (this.phoneNumberValidateCallback == null || value == 90000000001) {
        return;
      }
      if ("" + this.countryCode == "86") {
        var iphoneNumber = phoneNumberRegExp;
        if (!iphoneNumber.test(value) && value) {
          this.phoneNumberValidateCallback(
            getString(strings.Input_Please_Enter_A_Valid_Phone_Number)
          );
        } else {
          this.phoneNumberValidateCallback();
        }
      } else {
        if ((value + "").length <= 4 || (value + "").length >= 20) {
          this.phoneNumberValidateCallback(
            getString(strings.Input_Please_Enter_A_Valid_Phone_Number)
          );
        } else {
          this.phoneNumberValidateCallback();
        }
      }
    },

    // 验证码验证
    validataToCode(rule, value, callback) {
      this.captchaValidateCallback = callback;
      if ((value + "").length > 1 || (value + "").length < 6) {
        callback();
      } else {
        const iCaptcha = /^[0-9]*$/;
        if (!(!iCaptcha.test(value) && value)) {
          callback();
        }
      }
    },
    //邀请码验证
    invitationToCode(rule, value, callback) {
      const iCaptcha = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
      if (!iCaptcha.test(value) && value) {
        callback("邀请码输入错误");
      } else {
        callback();
      }
    },
    captchaBlurForValidate(e) {
      if (this.captchaValidateCallback == null) {
        return;
      }
      let value = this.$refs.CodeValue.value;
      if ((value + "").length <= 1 || (value + "").length >= 6) {
        this.captchaValidateCallback(
          getString(
            strings.Input_The_Please_Enter_The_Correct_Verification_Code
          )
        );
      } else {
        const iCaptcha = /^[0-9]*$/;
        if (!iCaptcha.test(value) && value) {
          this.captchaValidateCallback(
            getString(strings.Input_The_Verification_Code_Error)
          );
        } else {
          this.captchaValidateCallback();
        }
      }
    },
  },
  destroy() {
    clearInterval();
  },
};
</script>


<style scoped>
.Login-content {
  /* background-image: url(../../assets/img/page/reg_bg_min.jpg); */
  background-size: cover;
  /* width: 1900px; */
  min-height: 1920px;
  min-height: 99vh;
}
.hello {
  width: 600px;
  min-width: 800px;
  min-height: 99.9vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-content-box {
  position: relative;
  border-radius: 10px;
  display: flex;
  height: 600px;
  margin: 0 auto;
  width: inherit;
  position: relative;
  background-color: #ffffff;
}
.login-content-icon-img {
  margin-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-content-icon-img img {
  width: 528px;
  height: 456px;
}
.login-right {
  padding: 1em 8.5em;
  margin-top: 1em;
}
.login-right .row {
  width: 363px;
  /* border: 1px red solid; */
}
.login-right .lright-cont {
  width: 94%;
  margin-left: 1em;
  min-width: auto;
  /* border: 1px rgb(0, 140, 255) solid; */
}
.Login-container {
  padding: 20px 0px;
}

.login-right .lc-titel {
  display: flex;
}
.lc-titel h2 {
  font-size: 24px;
  font-family: Microsoft YaHei;
  /*font-weight: bold;*/
  line-height: 31px;
  color: #404040;
  opacity: 1;
}
.sign-a {
  color: #fd492b;
  line-height: 45px;
}
/*QR code*/
.Code-titel {
  width: 100%;
  text-align: center;
}
.Login-code {
  width: 52px;
  height: 52px;
  position: absolute;
  right: 0px;
}
.Code-bottom-text {
  text-align: center;
}
.Code-bottom-text p {
  margin-top: 5px;
}
.Login-code img {
  width: 100%;
}
.Code-content img {
  width: 100px;
  height: 100px;
}
.Code-box {
  width: 100px;
  margin: 0 auto;
}
.login-content {
  margin: auto;
}
.login-content .ai-group {
  width: 334px;
  height: 56px;
  /* padding-left: 20%; */
  /* border-radius: 4px; */
}
.login-content .pcode {
  padding-left: 13% !important;
}
.login {
  height: 100%;
  position: relative;
  margin-top: 10px;
}

/*ant design style*/
.ant-form {
  width: 400px;
}
.Login-Button {
  width: 334px;
  height: 52px;
}
.checked-box {
  position: relative;
  top: -30px !important;
}
#register_agreement {
  border-color: red;
}
/* 更改 */
.ant-btn-primary {
  margin-left: -50% !important;
  background-color: #fd492b !important;
  border-color: #fd492b !important;
  border-radius: 26px;
  font-size: 18px;
  /* margin-left: -7.5em; */
}
.ant-btn-primary:hover {
  background-color: #ff7354 !important;
  border-color: #ff7354 !important;
}
/* .ant-btn:hover{
  background-color: #fb3848;
  color: #ffffff;
  border: 1px solid #fb3848;
} */
.password-a {
  color: #fd492b;
  float: right;
}
.lc-zi {
  float: right;
}

.sign-a:hover {
  color: #ff7354;
}
.ho-a:hover {
  color: #ff7354;
}
.password-label {
  color: #fd492b;
  float: left;
}
.dr-agroup {
  display: flex;
  width: 334px;
}
.dr-agroup .dra-btn {
  float: right;
  width: 59px;
  height: 54px;
  background-color: #fd492b;
  border: none;
  font-size: 16px;
  color: #fff;
  opacity: 1;
  border-radius: 4px;
  margin-left: 10px;
  padding: unset;
}
.dr-agroup .dra-btn:hover {
  background-color: #ff7354 !important;
  border-color: #ff7354 !important;
}
.dr-agroup .scode {
  color: #999999;
  background-color: #e8e8e8 !important;
}
.dr-agroup .scode:hover {
  background-color: #e8e8e8 !important;
  border-color: #e8e8e8 !important;
}

/*模态框 */
.drop-down {
  position: absolute;
  z-index: 5;
  margin-top: 15px;
  margin-left: 15px;
  /* margin: 0px 15px; */
  align-items: center;
}
.drop-down-before {
  position: absolute;
  z-index: 5;
  margin-top: 15px;
  margin-right: 56px;
  right: 0;
  color: #b3b3b3;
}
.d-quhao {
  margin-top: 9px;
  display: flex;
}
.flag-list-box {
  background-color: #fff !important;
  width: 304px !important;
}
.login-content .phonr {
  padding-left: 46%;
}
.ant-select-selection {
  border: none;
  background: none;
}
.login-content .dr-deon {
  margin: 8px;
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 20px;
  color: #333333;
}
.login-content .lbtwo {
  margin-bottom: unset;
  padding-top: 20px;
}
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: red;
  border: none;
  color: rgba(0, 0, 0, 0.8);
}
.ant-form-item {
  margin-bottom: 24px;
}
/* .login-content .ai-group:hover {
  border: 1px #555 solid;
}
.ant-input:focus {
  border: 1px #555 solid;
  box-shadow: unset;
} */
/* .flag-list-box {
  background-color: #fff !important;
  width: 304px !important;
} */
</style>
<style lang="less" scoped>
@media screen and(min-width:250px) and(max-width:600px) {
  /* 更改 */
  .ant-btn-primary {
    margin-left: 0% !important;
    background-color: #fd492b !important;
    border-color: #fd492b !important;
    border-radius: 26px;
    font-size: 18px;
    /* margin-left: -7.5em; */
  }
}
@media screen and(min-width:250px) and(max-width:500px) {
  .flag-list-box {
    width: 258px !important;
  }
  .login-content-box {
    height: 100%;
  }
  .Login-header {
    width: 100%;
    position: static;
  }
  .hello {
    width: 100%;
    min-width: 0;
    .login-right {
      padding: 0;
      margin-top: 0;
      margin: 0 auto;
      width: 100%;
      .row {
        width: 100%;
      }
      .lright-cont {
        margin: 10px;
      }
    }
  }
  .Login-code {
    width: 30px;
    height: 30px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .get-back {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    font-size: 16px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .login-content-icon-img {
    display: none;
  }
  .login-content {
    padding-top: 0;
    .login {
      margin-top: 15px;
      .ant-form {
        width: 100%;
        .ant-form-item {
          .ant-col {
            .ant-form-item-control {
              .ant-form-item-children {
                .dr-agroup {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .lbtwo {
      padding-top: 0px;
      height: 23px;
    }
  }
  .Login-Button {
    height: 48px;
    margin-top: 11px;
    width: 100%;
  }
  .lc-titel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    h2 {
      line-height: 42px;
      font-size: 18px;
    }
    .lc-titel-a {
      width: auto !important;
    }
  }
}
</style>