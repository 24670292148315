<template>
  <div class="login-content-box">
    <div class="login-right">
      <div class="account-loginDiv">
        <a href="javascript:;" @click="ClickShowCode" class="account-login">
          <a-icon class="acc-ion" type="left" /> {{getString(strings.User_Lolgin_Account_Login)}}
        </a>
      </div>
      <div class="row">
        <div class="Login-header Code-titel">
          <div class="lc-titel">
            <span>{{getString(strings.User_Lolgin_Scan_Code_Login)}}</span>
          </div>
        </div>
        <div class="login-content">
          <div class="login" v-if="showUrlRaffirm">
            <div class="code-box">
              <div id="qrCode" ref="qrCodeDiv">
                <!-- <img class="qrcodeIco" id="qrCodeIco" src="../../../assets/img/code/logobai.png" alt=""> -->
              </div>
              <div class="center-box">
                <img
                  id="qrCodeIco"
                  src="../../../assets/img/code/logobai.png"
                  alt=""
                />
              </div>
              <!-- <vue-qr text="codeUrl" :logoSrc="imageUrl" id="qrCode" ref="qrCodeDiv" ></vue-qr> -->
            </div>
            <div class="Code-bottom-text">
              <p>{{getString(strings.User_Lolgin_Open_Mind_And_Scan_At_The_Top_Of_The_Setting_Interface)}}</p>
            </div>
          </div>
          <p class="r-overdue ss" v-if="showStaleDated">
            <span>{{getString(strings.User_Lolgin_This_QR_Code_Expires_In_Five_Minutes_Please_Click)}}</span>
            <a href="javascript:;" @click="getGouqi()">{{getString(strings.User_Lolgin_Refresh)}}</a>
          </p>
          <div class="rr-affirm" v-if="showRaffirm">
            <div>
              <img src="../../../assets/img/code/affirm0saoma.png" alt="" />
            </div>
            <p class="raffzi" style="margin: 10% 0px">
              {{getString(strings.User_Lolgin_Scanning_Is_Successful_Please_Confirm_On_Your_Phone)}}<br />{{getString(strings.User_Lolgin_Whether_Yo_Authorize_Login)}}
            </p>
          </div>
        </div>
        <!-- <div class="Login-code" @click="ClickShowCode">
          <img src="../../../assets/img/code/qrcode.png" alt />
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import vueQr from "vue-qr";
import { mapMutations } from "vuex";
import {
  postUserQrcodeGet,
  postUserQrcodeStatus,
  postUserMe,
} from "../../../common/netWork/base_api";
import { doLogin, setJwt } from "../../../common/netWork/base";
import QRCode from "qrcodejs2";
import sign from "../../../assets/css/login/sign.less";
import strings from "../../../common/lang/strings";
import getString from "../../../common/lang/language";
export default {
  name: "Login",
  inject: ['reload'],
  data() {
    return {
      confirmDirty: false,
      autoCompleteResult: [],
      ShowCode: true,
      imageUrl: require("../../../assets/img/code/logobai.png"),
      code: "", //二维码对象
      codeUrl: "", //网址
      showUrlRaffirm: true,
      showStaleDated: false, //二维码五分钟过期
      showRaffirm: false, //扫描成功，手机确认
      strings: strings,
    };
  },
  components: {
    vueQr,
  },
  created() {},
  mounted: function () {
    // this.getCode()
    this.$nextTick(() => {
      this.getCode();
      // this.bindQRCode();
    });
  },
  methods: {
    ...mapMutations(["showLoginModel","setRefreshMyGroupList"]),
    getString(i) {
      return getString(i);
    },
    bindQRCode(url) {
      return new QRCode(this.$refs.qrCodeDiv, {
        text: url,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        // correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
      });
      //控制logo图标的位置
      // var margin = ($("#qrCode").height() - $("#qrCodeIco").height()) / 2;
      // $("#qrCodeIco").css("margin", margin);
    },
    userMessage(obj = {}) {
      let that = this;
      return new Promise(function (resolve, reject) {
        postUserMe(
          obj,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
            that.$message.error(error.desc);
          }
        );
      });
    },
    rollPoling(url) {
      let timer = setInterval(() => {
        postUserQrcodeStatus(
          { url: url },
          (data) => {
            if (data.status == 0) {
              this.showStaleDated = false;
            } else if (data.status == 1) {
              this.showRaffirm = true;
              this.showUrlRaffirm = false;
            } else if (data.status == 2) {
              setJwt(data.jwt);
              this.$message.success(getString(strings.Message_Tips_Scan_Code_Login_Success));
              this.userMessage().then((res) => {
                doLogin(res);
                this.showLoginModel(false);
                this.$emit("ShowHome", false);
                this.setRefreshMyGroupList();
                clearInterval(timer);
                // if(this.$route.path.includes('Home')){
                //     this.$router.push("/Home/MyMind");
                // }
                this.$emit('loginSucceededFun');
              });
              return;
            } else if (data.status == 3) {
              this.$message.error(getString(strings.Message_Tips_Scan_Code_Login_Failed));
              this.ClickShowCode();
              clearInterval(timer);
              return;
            } else if (data.status == 4) {
              setTimeout(() => {
                this.showStaleDated = true;
              }, 300000); //超过五分钟二维码过期300000

              clearInterval(timer);
              // return
            }
            if (data.reason != "") {
              this.$message.error(data.reason);
              clearInterval(timer);
            }
          },
          (error) => {
            this.$message.error(error.desc);
            clearInterval(timer);
          }
        );
      }, 3000);
      this.timer = timer;
    },
    getCode() {
      postUserQrcodeGet(
        {},
        (data) => {
          this.codeUrl = data.url;
          // console.log(data.url)
          this.bindQRCode(data.url);
          this.rollPoling(data.url);
        },
        (error) => {
          this.$message.error(error.desc);
        }
      );
    },
    // 二维码界面
    ClickShowCode() {
      //去除轮询
      clearInterval(this.timer);
      this.$emit("ClickShowCode", this.ShowCode);
    },
    // 登录扫码切换
    ClickLoginShow() {
      this.$emit("ClickLoginShow", "Login");
    },
    getGouqi() {
      let code = document.getElementById("qrCode");
      code.innerHTML = "";
      this.getCode();
    },
  },
};
</script>

<style scoped>
.Login-content {
  /* background-image: url(../../assets/img/page/reg_bg_min.jpg); */
  background-size: cover;
  /* width: 1900px; */
  min-height: 1920px;
  min-height: 99vh;
}
.hello {
  width: 600px;
  min-width: 800px;
  min-height: 99.9vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-content-box {
  position: relative;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  height: 505px;
  margin: 0 auto;
  width: inherit;
  position: relative;
  background-color: #ffffff;
}
 
.login-right {
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}
.login-right .account-loginDiv {
  width: 100%;
  padding-top: 1em;
}
.login-right .row {
  width: 363px;
  height: 406px;
  text-align: center;
  margin-top: 3em;
}

.lc-titel span {
  /*font-weight: bold;*/
  font-size: 22px;
}

.Login-code {
  width: 60px;
  height: 60px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  cursor: pointer;
}
.Code-bottom-text {
  text-align: center;
}
.Code-bottom-text a {
  color: #666666;
}
.Code-bottom-text p {
  margin-top: 12px;
}
.Login-code img {
  width: 100%;
}
.code-box .qrcodeIco {
  position: absolute;
  top: 30%;
  right: 39%;
}
.code-box {
  position: relative;
  /* width: 100px; */
  margin: 0 auto;
}
.center-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 68px;
  height: 68px;
  background-color: #fff;
}
#qrCode {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
.login-content {
  margin: auto;
}
.login {
  height: 100%;
  position: relative;
  margin-top: 20px;
}

/*ant design style*/
.ant-form {
  width: 400px;
}
.Login-Button {
  width: 80%;
}
.checked-box {
  position: relative;
  top: -30px !important;
}
#register_agreement {
  border-color: red;
}

.account-login {
  color: #fd492b;
  font-size: 14px;
  padding-left: 20px;
}
.account-login:hover {
  color: #ff7354;
  font-size: 14px;
}
.acc-ion {
  margin-right: 4px;
}
/*QR code*/
/* .Code-titel {
  width: 100%;
  text-align: center;
} */
/* .Login-code {
  width: 52px;
  height: 52px;
  position: absolute;
  right: 0px;
} */
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background: red;
  border: none;
  color: rgba(0, 0, 0, 0.8);
}
</style>

<style lang="less" scoped>
@media screen and(min-width:250px) and(max-width:500px) {
  .Login-header {
    display: none;
  }
  .flag-list-box {
    width: 258px !important;
  }
  .login-content-box {
    height: 100%;
  }
  .Login-header {
    width: 100%;
    position: static;
  }
  .hello {
    width: 100%;
    min-width: 0;
    .login-right {
      padding: 0;
      margin-top: 0;
      margin: 0 auto;
      width: 100%;
      .row {
        width: 100%;
      }
      .lright-cont {
        margin: 10px;
      }
    }
  }
  .Login-code {
    width: 30px;
    height: 30px;
    img {
      width: 30px;
      height: 30px;
    }
  }
  .get-back {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 2;
    font-size: 16px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
 
  .login-content {
    padding-top: 0;
    .login {
      margin-top: 15px;
      .ant-form {
        width: 100%;
        .ant-form-item {
          .ant-col {
            .ant-form-item-control {
              .ant-form-item-children {
                .dr-agroup {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .lbtwo {
      padding-top: 0px;
      height: 23px;
    }
  }
  .Login-Button {
    height: 48px;
    margin-top: 11px;
    width: 100%;
  }
  .lc-titel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    h2 {
      line-height: 42px;
      font-size: 18px;
    }
    .lc-titel-a {
      width: auto !important;
    }
  }
}
</style>